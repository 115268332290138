import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private readonly _snackBar = inject(MatSnackBar);

  openSnackBar(message: string, action: string = 'OK') {
    this._snackBar.open(message, action);
  }

  openSuccessSnackBar(message: string, action: string = 'OK') {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: ['green-snackbar', 'login-snackbar'],
    });
  }

  openFailureSnackBar(message: string, action: string = 'OK') {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: ['red-snackbar', 'login-snackbar'],
    });
  }
}

